module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"f24b64c2-61c9-4abb-a769-f3b6d4016f92","guideId":"208","siteUrl":"https://www.unionsleden.com","lang":"sv","typesCategories":{"stages":["31"],"stays":["55","56"],"eatDrink":["54"],"service":["72","73","119"],"packages":["117"],"seeDo":[]},"typesCategoriesIncluded":{"stages":["31"],"stays":["55","56"],"eatDrink":["54"],"service":["72","73","119"],"packages":["117"],"seeDo":[]},"imagePlaceholderColor":"#29292b","templates":{"type":"/build/b4353490-3f96-4c91-ab16-710c0cc5ca88/src/templates/type.js","site":"/build/b4353490-3f96-4c91-ab16-710c0cc5ca88/src/templates/site.js","page":"/build/b4353490-3f96-4c91-ab16-710c0cc5ca88/src/templates/page.js","articles":"/build/b4353490-3f96-4c91-ab16-710c0cc5ca88/src/templates/articles.js","articlesPost":"/build/b4353490-3f96-4c91-ab16-710c0cc5ca88/src/templates/articles-post.js","search":"/build/b4353490-3f96-4c91-ab16-710c0cc5ca88/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Unionsleden","short_name":"Unionsleden","description":"Cykla i två länder i ett område rikt på naturnära upplevelser","theme_color":"#29292b","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
